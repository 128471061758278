<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="ri-question-line pointer mr-2" style="font-size: 20px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button  v-if="SHOW_DASHBOARD_BTN" variant="primary" @click="AdminFaqAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-6 col-md-4 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchAdminFaqWithParams"
                  v-model="whereFilter.search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <b-col class="col-12 col-sm-6 col-md-4 col-lg-4 pr-0 mb-3">
                <div>
                  <div class="d-flex flex-row">
                      <select v-model="whereFilter.faq_module" class="form-control w-100" @change="setModuleName">
                        <template class="w-100">
                          <option class="w-100" v-for="(name, index) of moduleNameOptions" :key="index+'1'" :value="name.faq_module">
                            {{name.faq_module}}
                          </option>
                        </template>
                      </select>
                  </div>
                </div>
              </b-col>
            </b-row>
            <div class="iq-accordion career-style faq-style" v-for="(faq, index) of AdminFaqObjList" :key="index+'1'">
              <div class="iq-card iq-accordion-block accordion-active" v-if="index == 0">
                  <div class="active-faq clearfix">
                      <div class="col-sm-12">
                        <p class="accordion-title pr-5 pb-0 mb-1"><span class="pr-2">{{ index+1 }}</span><span v-html="faq.faq_title"></span>
                          <span class="font-size-20" style="display: block;" v-if="SHOW_DASHBOARD_BTN">
                            <i class="ri-ball-pen-fill m-0 mr-3" @click="AdminFaqEdit('MODAL', faq)"></i>
                            <i class="ri-delete-bin-line m-0 mr-3" @click="showAdminFaqDeleteDialog(faq.faq_id)"></i>
                            <i title="Unpublish"  :disabled="faq.deleted === 2" @click="faqPublishUnpublish(faq)" class="fa fa-toggle-off m-0 mr-3 font-size-20" style="color:red"></i>
                            <i title="Publish" :disabled="faq.deleted === 0" @click="faqPublishUnpublish(faq)" class="fa fa-toggle-on m-0 font-size-20" style="color:green"></i>
                        </span>
                        </p>
                    </div>
                  </div>
                  <div class="accordion-details">
                    <p class="mb-0 p-0" v-html="faq.faq_description"></p>
                  </div>
              </div>
              <div class="iq-card iq-accordion-block" v-else>
                  <div class="active-faq clearfix">
                      <div class="col-sm-12">
                        <p class="accordion-title pr-5 pb-0 mb-1" ><span class="pr-2">{{ index+1 }}</span><span v-html="faq.faq_title"></span>
                          <span class="font-size-20" style="display: block;" v-if="SHOW_DASHBOARD_BTN">
                            <i class="ri-ball-pen-fill m-0 mr-3" @click="AdminFaqEdit('MODAL', faq)"></i>
                            <i class="ri-delete-bin-line m-0 mr-3" @click="showAdminFaqDeleteDialog(faq.faq_id)"></i>
                            <i title="Unpublish"  :disabled="faq.deleted === 2" @click="faqPublishUnpublish(faq)" class="fa fa-toggle-off m-0 mr-3 font-size-20" style="color:red"></i>
                            <i title="Publish" :disabled="faq.deleted === 0" @click="faqPublishUnpublish(faq)" class="fa fa-toggle-on m-0 font-size-20" style="color:green"></i>
                        </span>
                        </p>
                    </div>
                  </div>
                  <div class="accordion-details" style="display:none">
                    <p class="mb-0 p-0" v-html="faq.faq_description"></p>
                  </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelAdminFaqAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <AdminFaqAdd :propOpenedInModal="true" @emitCloseAdminFaqAddModal="closeAdminFaqAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="AdminFaqAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeAdminFaqAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelAdminFaqEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <AdminFaqEdit :propOpenedInModal="true" :propAdminFaqObj="AdminFaqEditObj" @emitCloseAdminFaqEditModal="closeAdminFaqEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="AdminFaqEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeAdminFaqEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelAdminFaqDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelAdminFaqDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="AdminFaqDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { AdminFaq } from "../../../FackApi/api/AdminFaq.js"
import AdminFaqAdd from "./AdminFaqAdd.vue"
import AdminFaqEdit from "./AdminFaqEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "AdminFaqList",
  components: {
    AdminFaqAdd,
    AdminFaqEdit
  },
  data () {
    return {
      cvCardTitle: "FAQs",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit FAQs",
      cvAddModalHeader: "Add FAQs",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "FAQ",
      showModelAdminFaqAdd: false,
      showModelAdminFaqEdit: false,
      showModelAdminFaqDelete: false,
      moduleNameOptions: [],
      sortBy: "name",
      sortDesc: false,
      AdminFaqObjList: [],
      AdminFaqEditObj: null,
      filter: null,
      filterOn: [],
      delObj: null,
      moduleName_list: [],
      SHOW_DASHBOARD_BTN: false,
      user_role: null,
      whereFilter: {
        faq_module: "testprep-sat",
        search_param: ""
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    if (this.$route.path === "/faq_list_dashboard" && this.userData && this.userData.user_role !== "USERROLE11111") {
      this.$router.back()
    }

    if (this.$route.path === "/faq_list_dashboard" && this.userData && (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118")) {
      this.SHOW_DASHBOARD_BTN = true
      this.user_role = this.userData ? this.userData.user_role : "USERROLE11114"
    }
    else {
      // this.$route.path === "/faq_list"
      this.whereFilter = { "delete": "SHOW_FAQ_PUBLIC" }
    }

    if (this.$route.params.gpath) {
      this.whereFilter.faq_module = "gpaths"
    }

    this.AdminFaqModuleList()
    this.AdminFaqList()
  },
  methods: {
    /*
    *
    */
    async setModuleName () {
      this.AdminFaqList()
    },
    /**
     * searchAdminFaqWithParams
     */
    async searchAdminFaqWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.AdminFaqList()
    },
    /**
     * AdminFaqList
     */
    async AdminFaqList () {
      try {
        this.whereFilter.user_role = this.user_role
        if (this.whereFilter.search_param && this.whereFilter.search_param.length >= 0) {
          this.whereFilter.faq_module = null
        }

        let AdminFaqListResp = await AdminFaq.AdminFaqList(this, this.whereFilter)
        if (AdminFaqListResp.resp_status) {
          if (this.whereFilter.search_param && this.whereFilter.search_param.length >= 0) {
            this.AdminFaqObjList = [ ...AdminFaqListResp.resp_data.data ]
          }
          else {
            this.AdminFaqObjList = AdminFaqListResp.resp_data.data
          }

          // Adding the serial numbers:
          this.AdminFaqObjList.forEach((s, index) => { s.id = index + 1 })
        }
        else {
          this.toastMsg = AdminFaqListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at AdminFaqList() and Exception:", err)
      }
    },
    /**
    * AdminFaqModuleList
    */
    async AdminFaqModuleList () {
      try {
        let AdminFaqModuleListResp = await AdminFaq.AdminFaqModuleList()
        if (AdminFaqModuleListResp && AdminFaqModuleListResp.resp_status) {
          this.AdminFaqModuleListResp = [ ...AdminFaqModuleListResp.resp_data.data ]
          this.moduleNameOptions = this.AdminFaqModuleListResp
        }
      }
      catch (err) {
        console.error("Exception occurred at AdminFaqList() and Exception:", err)
      }
    },
    /**
     * AdminFaqAdd
     */
    AdminFaqAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("../faq_add")
        }
        else {
          this.showModelAdminFaqAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at AdminFaqAdd() and Exception:", err.message)
      }
    },
    /**
     * AdminFaqEdit
     */
    AdminFaqEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("../faq_edit/" + this.AdminFaqEditObj.faq_id)
        }
        else {
          this.AdminFaqEditObj = item
          this.showModelAdminFaqEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at AdminFaqEdit() and Exception:", err.message)
      }
    },
    /**
     * showAdminFaqDeleteDialog
     */
    showAdminFaqDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelAdminFaqDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showAdminFaqDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * AdminFaqDelete
     */
    async AdminFaqDelete () {
      try {
        let adminFaqDelResp = await AdminFaq.AdminFaqDelete(this, this.delObj)
        await ApiResponse.responseMessageDisplay(this, adminFaqDelResp)

        if (adminFaqDelResp && !adminFaqDelResp) {
          this.showModelAdminFaqDelete = false
          return false
        }

        let index = this.AdminFaqObjList.indexOf(this.delObj)
        this.AdminFaqObjList.splice(index, 1)
        this.showModelAdminFaqDelete = false
      }
      catch (err) {
        console.error("Exception occurred at AdminFaqDelete() and Exception:", err.message)
      }
    },
    /**
     * closeAdminFaqAddModal
     */
    closeAdminFaqAddModal (AdminFaqAddData) {
      try {
        if (AdminFaqAddData) {
          if (this.AdminFaqObjList && this.AdminFaqObjList.length >= 1) {
            let adminFaqObjLength = this.AdminFaqObjList.length
            let lastId = this.AdminFaqObjList[adminFaqObjLength - 1]["id"]
            AdminFaqAddData.id = lastId + 1
          }
          else {
            this.AdminFaqObjList = []
            AdminFaqAddData.id = 11111
          }

          AdminFaqAddData.created_on = moment()
          this.AdminFaqObjList.unshift(AdminFaqAddData)
        }

        this.showModelAdminFaqAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeAdminFaqAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeAdminFaqEditModal
     */
    closeAdminFaqEditModal () {
      try {
        this.showModelAdminFaqEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeAdminFaqEditModal() and Exception:", err.message)
      }
    },
    /**
     * Publish/UnPublish Faq
     */
    async faqPublishUnpublish (data) {
      let payload = {
        faq_id: data.faq_id,
        deleted: null
      }
      if (data.deleted === 0) {
        // noti is published then unpublish
        payload.deleted = 2
      }
      else if (data.deleted === 2) {
        payload.deleted = 0
      }
      else {
        return
      }
      let faqResp = await AdminFaq.admin_faq_publish_unpublish(this, payload)
      await ApiResponse.responseMessageDisplay(this, faqResp)

      if (faqResp.resp_status === true) {
        data.deleted = payload.deleted
      }
      else {
        ApiResponse.responseMessageDisplay(this, faqResp)
      }
    }
  }
}
</script>
<style>
.iq-accordion-block{
  border: 1px solid #f1f1f1 !important;
  margin-bottom: 10px !important;
  padding: 20px !important;
}
.accordion-details p p{
  padding: 0px !important;
  margin-bottom:0px;
  margin-top:10px ;
}
.accordion-title span p {
  margin-bottom: 0px;
}
</style>
